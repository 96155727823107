$length-1: .1rem;
$length-2: .2rem;
$length-4: .4rem;
$length-6: .6rem;
$length-8: .8rem;
$length-10: 1rem;
$length-12: 1.2rem;
$length-14: 1.4rem;
$length-16: 1.6rem;
$length-18: 1.8rem;
$length-20: 2.0rem;
$length-24: 2.4rem;
$length-32: 3.2rem;
$length-38: 3.8rem;
$length-48: 4.8rem;
$length-64: 6.4rem;
$length-96: 9.4rem;
$length-128: 12.8rem;
$length-192: 19.2rem;
$length-256: 25.6rem;
$length-384: 38.4rem;
$length-512: 51.2rem;
$length-640: 64.0rem;
$length-768: 76.8rem;
