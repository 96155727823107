.content-linkbox-list {
    --color-linkbox-head: #{$grey-600};
    --color-linkbox-body: #{$grey-500};
    --color-linkbox-tail: #{$grey-400};

    --color-linkbox-head-hover: #{$grey-700};
    --color-linkbox-body-hover: #{$grey-600};
    --color-linkbox-tail-hover: #{$grey-500};

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin: 0 0 0 -#{$length-20};

    &.is-vertical {
        flex-direction: column;

        .linkbox-item {
            width: 100%;
        }
    }

    .linkbox-item {
        flex: 0 0 auto;

        width: 33.33%;
        width: calc(33.33% - 20px);

        @include wide {
            width: 25%;
            width: calc(25% - 20px);
        }

        @include until-desktop {
            width: 50%;
            width: calc(50% - 20px);
        }

        @include mobile {
            width: 100%;
            width: calc(100% - 20px);
        }

        margin: 0 0 $length-20 $length-20;

        color: $true-white;

        .icon {
            width: 25px;
            background: $true-white;
        }

        .head {
            display: flex;
            padding: $length-10;

            h3 {
                margin: 0;
                padding: 0;

                width: 100%;

                font-size: $font-size-6;
                font-weight: normal;
                line-height: 1.3;

                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .body {
            display: block;
            padding: $length-10 $length-10 $length-6 $length-10;
            font-size: $font-size-10;
            line-height: 1.5;

            border-top: solid $length-1 rgba($true-black, .2);

            p {
                margin: 0 0 $length-6 0;
                padding: 0;

                &.right {
                    text-align: right;
                }
            }
        }

        .tail {
            display: flex;
            padding: $length-10;

            border-top: solid $length-1 rgba($true-white, .2);

            font-size: $font-size-9;

            p {
                margin: 0;
                padding: 0;

                width: 100%;
                line-height: 1.6;

                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        // onlink transformation
        a {
            position: relative;

            color: $true-white;
            text-decoration: none;

            &:after {
                content: '';

                position: absolute;
                top: $length-16;
                right: $length-16;

                width: $length-10;
                height: $length-10;


                border-color: rgba($white, .5);
                border-style: solid;
                border-width: $length-2 $length-2 0 0;

                transform: rotate(45deg);
            }

            &:hover:after, &:focus:after {
                border-color: $white;
            }

            &.head { padding-right: 30px; }
            &.head:after { top: 20px; }
            &.body:after { display: none; }
            &.tail { padding-right: 30px; }
        }

        // Boxes colours
        .head { background: var(--color-linkbox-head); }
        .body { background: var(--color-linkbox-body); }
        .tail { background: var(--color-linkbox-tail); }

        a:hover, a:focus {
            &.head { background: var(--color-linkbox-head-hover); }
            &.body { background: var(--color-linkbox-body-hover); }
            &.tail { background: var(--color-linkbox-tail-hover); }
        }

        &.primary {
            --color-linkbox-head: #{$primary-700};
            --color-linkbox-body: #{$primary-500};
            --color-linkbox-tail: #{$primary-300};

            --color-linkbox-head-hover: #{$primary-800};
            --color-linkbox-body-hover: #{$primary-600};
            --color-linkbox-tail-hover: #{$primary-400};
        }

        &.secondary {
            --color-linkbox-head: #{$accent-800};
            --color-linkbox-body: #{$accent-700};
            --color-linkbox-tail: #{$accent-600};

            --color-linkbox-head-hover: #{$accent-900};
            --color-linkbox-body-hover: #{$accent-800};
            --color-linkbox-tail-hover: #{$accent-800};
        }
    }
}
