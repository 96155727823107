.ico {
    background-repeat: no-repeat;
    @include sprite();
}

.ico-after,
.custom-block-body {
    position: relative;

    &:after {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        @include sprite();
    }

    &.alert, &.ico-alert {
        &:after {
            @include sprite-position($alert);
        }
        &.blue:after {
            @include sprite-position($alert-blue);
        }
        &.light:after {
            @include sprite-position($alert-light);
        }
    }

    &.arrow-left {
        &:after {
            @include sprite-position($arrow-left);
        }
        &.blue:after {
            @include sprite-position($arrow-left-blue);
        }
        &.light:after {
            @include sprite-position($arrow-left-light);
        }
    }

    &.offline,
    &.arrow-right {
        &:after {
            @include sprite-position($arrow-right);
        }
        &.blue:after {
            @include sprite-position($arrow-right-blue);
        }
        &.light:after {
            @include sprite-position($arrow-right-light);
        }
    }

    &.beta {
        &:after {
            @include sprite-position($beta);
        }
        &.blue:after {
            @include sprite-position($beta-blue);
        }
        &.light:after {
            @include sprite-position($beta-light);
        }
    }

    &.cite {
        &:after {
            @include sprite-position($cite);
        }
        &.blue:after {
            @include sprite-position($cite-blue);
        }
        &.light:after {
            @include sprite-position($cite-light);
        }
    }

    &.cross {
        &:after {
            @include sprite-position($cross);
        }
        &.blue:after {
            @include sprite-position($cross-blue);
        }
        &.red:after {
            @include sprite-position($cross-red);
        }
        &.light:after {
            @include sprite-position($cross-light);
        }
        &.white:after {
            @include sprite-position($cross-white);
        }
    }

    &.download {
        &:after {
            @include sprite-position($download);
        }
        &.blue:after {
            @include sprite-position($download-blue);
        }
        &.light:after {
            @include sprite-position($download-light);
        }
    }

    &.downvote {
        &:after {
            @include sprite-position($thumb-down);
        }
        &.voted:after {
            @include sprite-position($thumb-down-voted);
        }
    }

    &.edit {
        &:after {
            @include sprite-position($edit);
        }
        &.blue:after {
            @include sprite-position($edit-blue);
        }
        &.light:after {
            @include sprite-position($edit-light);
        }
    }

    &.email {
        &:after {
            @include sprite-position($email);
        }
        &.blue:after {
            @include sprite-position($email-blue);
        }
        &.light:after {
            @include sprite-position($email-light);
        }
    }

    &.diaspora {
        &:after {
            @include sprite-position($diaspora);
        }
        &.blue:after {
            @include sprite-position($diaspora-blue);
        }
        &.light:after {
            @include sprite-position($diaspora-light);
        }
    }

    &.discord {
        &:after {
            @include sprite-position($discord);
        }
        &.blue:after {
            @include sprite-position($discord-blue);
        }
        &.light:after {
            @include sprite-position($discord-light);
        }
    }

    &.facebook {
        &:after {
            @include sprite-position($facebook);
        }
        &.blue:after {
            @include sprite-position($facebook-blue);
        }
        &.light:after {
            @include sprite-position($facebook-light);
        }
    }

    &.gear {
        &:after {
            @include sprite-position($gear);
        }
        &.blue:after {
            @include sprite-position($gear-blue);
        }
        &.light:after {
            @include sprite-position($gear-light);
        }
    }

    &.github {
        &:after {
            @include sprite-position($github);
        }
        &.blue:after {
            @include sprite-position($github-blue);
        }
        &.light:after {
            @include sprite-position($github-light);
        }
    }

    &.help {
        &:after {
            @include sprite-position($help);
        }
        &.blue:after {
            @include sprite-position($help-blue);
        }
        &.light:after {
            @include sprite-position($help-light);
        }
    }

    &.hide {
        &:after {
            @include sprite-position($hide);
        }
        &.blue:after {
            @include sprite-position($hide-blue);
        }
        &.light:after {
            @include sprite-position($hide-light);
        }
    }

    &.history {
        &:after {
            @include sprite-position($history);
        }
        &.blue:after {
            @include sprite-position($history-blue);
        }
        &.light:after {
            @include sprite-position($history-light);
        }
    }

    &.import {
        &:after {
            @include sprite-position($import);
        }
        &.blue:after {
            @include sprite-position($import-blue);
        }
        &.light:after {
            @include sprite-position($import-light);
        }
    }

    &.lock {
        &:after {
            @include sprite-position($lock);
        }
        &.blue:after {
            @include sprite-position($lock-blue);
        }
        &.light:after {
            @include sprite-position($lock-light);
        }
    }

    &.more {
        &:after {
            @include sprite-position($more);
        }
        &.blue:after {
            @include sprite-position($more-blue);
        }
        &.light:after {
            @include sprite-position($more-light);
        }
    }

    &.move {
        &:after {
            @include sprite-position($move);
        }
        &.blue:after {
            @include sprite-position($move-blue);
        }
        &.light:after {
            @include sprite-position($move-light);
        }
    }

    &.pin {
        &:after {
            @include sprite-position($pin);
        }
        &.blue:after {
            @include sprite-position($pin-blue);
        }
        &.light:after {
            @include sprite-position($pin-light);
        }
    }

    &.rss {
        &:after {
            @include sprite-position($rss);
        }
        &.blue:after {
            @include sprite-position($rss-blue);
        }
        &.orange:after {
            @include sprite-position($rss-orange);
        }
        &.light:after {
            @include sprite-position($rss-light);
        }
    }

    &.star {
        &:after {
            @include sprite-position($star);
        }
        &.yellow:after {
            @include sprite-position($star-yellow);
        }
        &.blue:after {
            @include sprite-position($star-blue);
        }
        &.light:after {
            @include sprite-position($star-light);
        }
    }

    &.stats {
        &:after {
            @include sprite-position($stats);
        }
        &.blue:after {
            @include sprite-position($stats-blue);
        }
        &.light:after {
            @include sprite-position($stats-light);
        }
    }

    &.tutorial {
        &:after {
            @include sprite-position($tutorial);
        }
    }

    &.article {
        &:after {
            @include sprite-position($article);
        }
    }

    &.opinion {
        &:after {
            @include sprite-position($opinion);
        }
    }

    &.content {
        &:after {
            @include sprite-position($content);
        }
    }

    &.tick {
        &:after {
            @include sprite-position($tick);
        }
        &.green:after {
            @include sprite-position($tick-green);
        }
        &.light:after {
            @include sprite-position($tick-light);
        }
    }

    &.X {
        &:after {
            @include sprite-position($X);
        }
        &.blue:after {
            @include sprite-position($X-blue);
        }
        &.light:after {
            @include sprite-position($X-light);
        }
    }

    &.mastodon {
        &:after {
            @include sprite-position($mastodon);
        }
        &.blue:after {
            @include sprite-position($mastodon-blue);
        }
        &.light:after {
            @include sprite-position($mastodon-light);
        }
    }

    &.unread {
        &:after {
            @include sprite-position($unread);
        }
    }

    &.upvote {
        &:after {
            @include sprite-position($thumb-up);
        }
        &.voted:after {
            @include sprite-position($thumb-up-voted);
        }
    }

    &.online,
    &.view {
        &:after {
            @include sprite-position($view);
        }
        &.blue:after {
            @include sprite-position($view-blue);
        }
        &.light:after {
            @include sprite-position($view-light);
        }
    }
}

h3,
h4,
h5,
h6 {
    &:hover span.icon-link:after {
        content: ' ';
        display: inline-block;
        @include sprite();
        @include sprite-position($zform-link);
        width: 16px;
        height: 16px;
    }
}

// As icons are not in the size grid, they are off-aligned for titles
// because of the close line under them. This is not a problem for other
// elements due to different layout.
h2.ico-after:after {
    top: -$length-2;
}
