.small-content-wrapper {
    width: 90%;
    max-width: $length-512;
    margin: $length-20 auto;
}

.main .content-container {
    .content-wrapper {
        &.article-content,
        &.members {
            padding-left: 2%;
            padding-right: 2%;
        }
    }

    header {
        >.title-block {
            display: flex;
            flex-direction: row;

            margin-bottom: $length-10;

            >.content-thumbnail-group {
                flex-grow: 0;
                flex-shrink: 0;

                display:block;
                height: $length-64;
                width: $length-64;

                margin-right: $length-10;

                background-size: contain;

                >.thumbnail {
                    width: 100%;
                    height: 100%;
                    border: $length-1 solid $grey-200;
                    box-sizing: border-box;
                }

                >.edit-thumbnail {
                    display: block;
                    width: $length-32;
                    height: $length-32;
                    margin-top: calc($length-64/2 - $length-32/2);
                    margin-left: calc($length-64/2 - $length-32/2);
                    border-radius: $radius-round;
                    background-color: rgba($grey-200, 0.7);
                    &.with-thumbnail {
                        position: relative;
                        top: -$length-64;
                    }
                    &.with-placeholder{
                        position: relative;
                        top: 0;
                    }
                    &::after {
                        content: " ";
                        display: block;
                        width: $length-16;
                        height: $length-16;
                        position: relative;
                        top: calc($length-32/2 - $length-16/2);
                        left: calc($length-32/2 - $length-16/2);
                        @include sprite;
                        @include sprite-position($edit-blue);
                        background-repeat: no-repeat;
                    }
                    transition: border-radius .005s ease-in-out;
                    &:hover {
                        border-radius: 0;
                        width: 100%;
                        height: 100%;
                        margin: 0;
                        &::after {
                            top: calc($length-64/2 - $length-16/2);
                            left: calc($length-64/2 - $length-16/2);
                        }
                    }
                }

                &.article-illu {
                    background-image: url("/static/images/article-illu.png");
                }

                &.tutorial-illu {
                    background-image: url("/static/images/tutorial-illu.png");
                }

                &.opinion-illu {
                    background-image: url("/static/images/opinion-illu.png");
                }
            }

            >.content-title-and-subtitle-group {
                width:100%;

                padding-bottom: $length-4;

                border-bottom: $length-1 solid $accent-500;

                >.content-title-group {
                    >.title {
                        display: inline;
                        vertical-align: middle;
                        border: none;
                        margin: 0;
                        padding: 0;
                    }

                    >.edit-button {
                        display: inline-block;
                        vertical-align: middle;
                    }
                }

                >.content-subtitle-group {
                    >.subtitle {
                        display: inline;
                        vertical-align: middle;
                        margin: 0;
                        padding: 0;
                    }

                    >.edit-button {
                        display: inline-block;
                        vertical-align: middle;
                    }
                }

                display: flex;
                flex-direction: column;
            }
        }
    }

    section > h2 {
        display: flex;

        span {
            flex: 2;
        }

        .btn {
            float: none;
        }
    }

    .article-content {
        .extract-wrapper {
            h3, h4, h5, h6 {
                &:first-child {
                    margin-top: $length-14;
                }
            }
        }

        p,
        > a,
        p a,
        th,
        td,
        ul:not(.pagination),
        ol:not(.summary-part) {
            font-family: $font-serif;
            font-size: $font-size-10;
        }
    }

    .content-wrapper.comment-author,
    .comment-author {
        margin-bottom: $length-20;
        padding: $length-8 $length-16;

        background: $grey-100;

        color: $grey-800;

        blockquote {
            margin: $length-10 0;
            padding: $length-6 0 $length-6 $length-16;

            border-left: $length-6 solid$grey-200;
        }
    }

    .article-content .summary-part {
        font-size: $font-size-7;
        color: $accent-800;

        h3,
        h4 {
            width: 90%;
            font-weight: normal;

            a {
                text-decoration: none;

                &:not(.btn) {
                    &:hover,
                    &:focus {
                        text-decoration: underline;
                    }
                }
            }
        }

        h3 {
            margin: 0 0 $length-6;
            font-size: $length-20;

            a {
                color: $accent-800;
            }
        }

        .summary-part {
            margin-bottom: $length-16;
            padding-left: 0;

            list-style: none;

            h4 {
                font-size: $font-size-10;
                margin: $length-2 0;
            }
        }
    }

    .article-content,
    .message-content {
        margin-bottom: $length-20;
        color: $black;

        @import "base/content";
    }

    .message-content {
        h3, h4, h5, h6 {
            &:first-child {
                margin-top: $length-14;
            }
        }
    }

    .comments-title {
        margin: $length-48 0 $length-20;
        border-bottom: $length-1 solid $color-secondary;

        color: $color-primary;

        font-size: $font-size-6;
        font-weight: normal;
    }

    p.is-dimmed {
        color: $grey-500;
    }
}

@include wide {
    .full-content-wrapper .tutorial-list article {
        width: 29.3%;
    }
}

@include desktop {
    .content-wrapper,
    .full-content-wrapper {
        margin: 0 0 0 4%;

        &.without-margin {
            margin: 0;
        }

        .content-wrapper {
            max-width: none;
            margin: 0;
        }
    }
}

@include until-desktop {
    .main .content-container {
        .taglist,
        .pubdate {
            margin-left: $length-10;
            margin-right: $length-10;
        }

        .article-content {
            p,
            ol,
            ul:not(.pagination) {
                font-size: $font-size-10;
            }
        }

        .content-wrapper,
        .full-content-wrapper {
            h1:not(.ico-after),
            h2:not(.ico-after),
            h3,
            .subtitle {
                padding-left: $length-10;
                padding-right: $length-10;
            }

            .content-thumbnail-group {
                display: none;
            }

            h4,
            h5,
            h6,
            .members,
            p,
            figure,
            blockquote,
            .title-block {
                margin-left: $length-10;
                margin-right: $length-10;
            }

            ul,
            ol {
                margin-right: $length-10;
            }

            figure {
                p,
                blockquote {
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            .license {
                margin-right: $length-6;
            }
        }
    }
}

@include mobile {
    .main .content-container .article-content .btn {
        float: none;
        text-align: center;
    }
}
