$dropdown-arrow-height: 10px;

details.dropdown {
    --dropdown-offset-right: 0;
    --dropdown-arrow-offset-right: #{$length-4};

    position: relative;

    summary {
        appearance: none;
        display: inline-block;
        cursor: pointer;

        &::-webkit-details-marker {
            display: none;
        }
        &::marker {
            display: none;
        }
    }

    &[open] {
        z-index: 10;
    }

    &[open] summary::after {
        content: '';
        cursor: default;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .dropdown-content {
        position: absolute;
        top: 100%;
        right: var(--dropdown-offset-right);

        margin: 0;
        padding: 0;

        z-index: 21;

        background-color: $true-white;
        filter: drop-shadow(0 3px 6px hsla(0, 0%, 0%, .15))
                drop-shadow(0 2px 4px hsla(0, 0%, 0%, .12));

        &::before {
            content: " ";
            position: absolute;
            display: block;
            width: #{$dropdown-arrow-height * 2};
            height: #{$dropdown-arrow-height};
            top: -#{$dropdown-arrow-height};
            right: var(--dropdown-arrow-offset-right);
            background-color: $true-white;
            clip-path: polygon(0 100%, 50% 0, 100% 100%);
        }
    }
}
