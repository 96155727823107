.autocomplete-wrapper {
    position: relative;

    .autocomplete-dropdown {
        position: absolute;
        z-index: 60;

        .autocomplete-dropdown-header {
            margin: 0;
            padding: 0;

            padding-left: $length-6;

            border-right: solid $length-1 $grey-200;
            border-left: solid $length-1 $grey-200;

            border-bottom-right-radius: $radius-1;
            border-bottom-left-radius: $radius-1;

            background-color: $true-white;
            font-weight: normal;
        }

        ul {
            margin: 0;
            padding: 0;

            border-right: solid 1px $grey-200;
            border-left: solid 1px $grey-200;

            border-bottom-right-radius: $radius-1;
            border-bottom-left-radius: $radius-1;

            background-color: $true-white;

            li {
                padding: $length-4 $length-10;
                border-bottom: solid $length-1 $grey-200;

                list-style: none;

                &.active, &:hover {
                    background-color: $primary-500;
                    color: $white;
                }
            }
        }
    }
}

.modal .autocomplete-dropdown {
    margin-top: -$length-16;
}
