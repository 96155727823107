.main {
    flex: 1;
    min-width: 0;
}

.main .content-container {
    padding-top: $length-24;

    button.ico-search {
        font-size: 0;
    }

    h1,
    h2 {
        margin: $length-1 0 $length-16;

        border-bottom: $length-1 solid $color-secondary;

        font-size: $font-size-6;
        font-weight: normal;
        line-height: 1.4;

        color: $color-primary;

        &.illu img {
            display: inline-block;

            width: $length-48;
            height: $length-48;

            margin: -$length-10 $length-4 -#{$length-10} 0;

            border: $length-1 solid $grey-200;
            background: $white;
        }

        &.ico-after {
            padding-left: $length-48;

            &:after {
                @include sprite-width($articles);
                @include sprite-height($articles);
            }
        }
        &.ico-opinions:after {
            @include sprite-position($opinions);
        }
        &.ico-articles:after {
            @include sprite-position($articles);
        }
        &.ico-tutorials:after {
            @include sprite-position($tutorials);
        }
        &.ico-news:after {
            @include sprite-position($star-heading-white);
        }
        &.ico-forum:after {
            @include sprite-position($forum-heading);
        }
        &.ico-tags:after {
            @include sprite-position($tags);
        }

        &.inline {
            display: inline-block;
        }

        .btn {
            font-size: $font-size-9;
            line-height: $length-38;
            height: $length-38;
        }
    }

    // TODO remove the few usages of this ugly beast.
    .force-blue {
        color: $primary-300 !important;
    }

    .simple-edit-button {
       text-align: right;

      .btn {
        display: inline-block;
        float: none;
      }
    }

    li.simple-create-button {
        list-style-type: '';

        .btn {
            display:inline-block;

            float:none;
            vertical-align: middle;

            margin-top: $length-8;
            margin-left: -$length-18;

            padding:0 $length-12 0 $length-8;

            height: $length-32;

            border-radius: $radius-1;
            line-height: 1.5;

            &::before {
                content: '+ ';

                padding-bottom: $length-4; // hack font vertical-align between + and ABC (letters)
                vertical-align: middle;

                font-size: $font-size-7;
            }

        }
    }

    .license {
        margin: 0;
    }

    .subtitle {
        margin-top: -$length-12;
        margin-bottom: $length-4;
        padding: $length-10 0;

        border-bottom: 1px solid $grey-000;

        color: $grey-600;

        font-size: $font-size-8;
        font-weight: normal;
        line-height: 1.5;
    }

    .pubdate {
        display: block;
        color: $grey-600;
        margin-bottom: $length-12;
    }

    .open-zen-mode {
        display: none;
    }

    .new-btn-container {
        display: none;
    }

    .summary-part h4 > a.disabled {
        background: none !important;
    }
}

.home .main .content-container {
    margin-top: 0;
}

.pagination-bottom-clear{
    clear: both;
}

@include extra-wide {
    .main .content-container {
        > .content-wrapper {
            max-width: 960px;
            margin: 0 auto !important;
        }
    }
}

@include desktop {
    body.no-sidebar .main {
        .content-container {
            width: 100%;
        }
        .sidebar {
            display: none;
        }
    }

    .main {
        display: flex;
        flex-direction: row-reverse;
        margin-left: 0;
        padding-left: 2.5%;
        min-width: 0;

        .content-container {
            width: 80%;
            margin-right: 0;

            .taglist + .pubdate {
                margin-top: -$length-38;
            }

            .open-zen-mode {
                display: block;
            }

            &.zen-mode {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;

                width: 100%;
                min-height: calc(100% - #{$length-192});

                margin-bottom: 0;
                padding: $length-96 0;

                background: $white;
                z-index: 20;

                .comments-title,
                .pagination:not(.pagination-chapter),
                .topic-message,
                .sidebar,
                .alert-box {
                    display: none;
                }

                .content-wrapper {
                    margin: 0 4%;
                }

                .summary-button {
                    display: unset;
                }
            }

            h1,
            h2 {
                margin-left: $length-1;
            }

            .content-col-2 {
                width: 49.5%;
                margin: 0 0 0 1%;
            }
            .content-col-3 {
                width: 32%;
                margin: 0 0 0 2%;
            }
            .content-col-2,
            .content-col-3 {
                float: left;

                &:first-child {
                    margin: 0;
                }
            }

            .article-content > .summary-part {
                display: flex;
                flex-wrap: wrap;

                > li {
                    width: 50%;
                }
            }
        }
    }
}

@include desktop {
    .content-cols .main {
        .content-container {
            width: 79%;
            margin-left: 1.5%;
        }
    }

    .full-content-wrapper .tutorial-list article {
        width: 46%;
        float: left;
        &.extend {
            width: 100%;
        }
    }
}

@include until-desktop {
    .main .content-container .new-btn-container {
        display: block;

        margin: $length-32 0;
        border-top: 1px solid $grey-200;

        overflow: hidden;

        .new-btn {
            display: block;

            width: 100%;
            height: $length-32;

            padding: $length-8 $length-10 $length-8 $length-32;

            background: $grey-100;
            border-bottom: $length-1 solid $grey-200;

            color: $grey-600;
            text-decoration: none;
            line-height: 2.2;

            &.ico-after:after {
                top: $length-16;
                left: $length-8;
            }
        }
    }

    .main .content-container {
        h1, h2 {
            &.ico-news:after {
                @include sprite-position($star-heading);
            }
        }
    }

    .main {
        width: 100%;
    }

    .main .content-container .content-col:not(:first-child),
    .main .sidebar {
        margin-top: $length-48;
    }

    .content-col-2:not(:first-child),
    .content-col-3:not(:first-child) {
        margin-top: $length-48;
    }
}
