$X-blue: -88px -120px 16px 40px;
$X-light: -104px -120px 16px 40px;
$X: -120px -120px 16px 40px;
$alert-blue: -136px -120px 16px 40px;
$alert-light: -152px -120px 16px 40px;
$alert: -175px 0px 16px 40px;
$alerts: -120px -32px 22px 40px;
$ariane: -303px -240px 15px 40px;
$arrow-left-blue: -175px -40px 16px 40px;
$arrow-left-light: -175px -80px 16px 40px;
$arrow-left: -175px -120px 16px 40px;
$arrow-right-blue: -191px 0px 16px 40px;
$arrow-right-light: -191px -40px 16px 40px;
$arrow-right: -191px -80px 16px 40px;
$article: -191px -120px 16px 40px;
$articles: 0px 0px 40px 40px;
$beta-blue: 0px -160px 16px 40px;
$beta-light: -16px -160px 16px 40px;
$beta: -32px -160px 16px 40px;
$chat-bubble-orange: -80px -80px 33px 32px;
$chat-bubble: -120px 0px 33px 32px;
$check: -192px -160px 13px 13px;
$cite-blue: -48px -160px 16px 40px;
$cite-light: -64px -160px 16px 40px;
$cite: -80px -160px 16px 40px;
$content: -96px -160px 16px 40px;
$contents: -40px 0px 40px 40px;
$cross-blue: -112px -160px 16px 40px;
$cross-light: -128px -160px 16px 40px;
$cross-red: -144px -160px 16px 40px;
$cross-white: -160px -160px 16px 40px;
$cross: -176px -160px 16px 40px;
$diaspora-blue: -207px 0px 16px 40px;
$diaspora-light: -207px -40px 16px 40px;
$diaspora: -207px -80px 16px 40px;
$discord-blue: -17px -280px 16px 16px;
$discord-light: -33px -280px 16px 16px;
$discord: -49px -280px 16px 16px;
$download-blue: -207px -120px 16px 40px;
$download-light: -207px -160px 16px 40px;
$download: -223px 0px 16px 40px;
$edit-blue: -223px -40px 16px 40px;
$edit-light: -223px -80px 16px 40px;
$edit: -223px -120px 16px 40px;
$email-blue: -223px -160px 16px 40px;
$email-light: -239px 0px 16px 40px;
$email: -239px -40px 16px 40px;
$error: -120px -72px 22px 40px;
$facebook-blue: -239px -80px 16px 40px;
$facebook-light: -239px -120px 16px 40px;
$facebook: -239px -160px 16px 40px;
$forum-heading: 0px -40px 40px 40px;
$gear-blue: 0px -200px 16px 40px;
$gear-light: -16px -200px 16px 40px;
$gear: -32px -200px 16px 40px;
$github-blue: -48px -200px 16px 40px;
$github-light: -64px -200px 16px 40px;
$github: -80px -200px 16px 40px;
$help-blue: -96px -200px 16px 40px;
$help-light: -112px -200px 16px 40px;
$help: -128px -200px 16px 40px;
$hide-blue: -144px -200px 16px 40px;
$hide-light: -160px -200px 16px 40px;
$hide: -176px -200px 16px 40px;
$history-blue: -192px -200px 16px 40px;
$history-light: -208px -200px 16px 40px;
$history: -224px -200px 16px 40px;
$import-blue: -255px 0px 16px 40px;
$import-light: -255px -40px 16px 40px;
$import: -255px -80px 16px 40px;
$information: -153px 0px 22px 40px;
$lock-blue: -255px -120px 16px 40px;
$lock-light: -255px -160px 16px 40px;
$lock: -255px -200px 16px 40px;
$mastodon-blue: -271px 0px 16px 40px;
$mastodon-light: -271px -40px 16px 40px;
$mastodon: -271px -80px 16px 40px;
$menu: -153px -40px 22px 40px;
$messages: -153px -80px 22px 40px;
$more-blue: -271px -120px 16px 40px;
$more-light: -271px -160px 16px 40px;
$more: -271px -200px 16px 40px;
$move-blue: 0px -240px 16px 40px;
$move-light: -16px -240px 16px 40px;
$move: -32px -240px 16px 40px;
$notifications: 0px -120px 22px 40px;
$opinion: -48px -240px 16px 40px;
$opinions: -40px -40px 40px 40px;
$params: -22px -120px 22px 40px;
$pin-blue: -64px -240px 16px 40px;
$pin-light: -80px -240px 16px 40px;
$pin: -96px -240px 16px 40px;
$pm-new-white: 0px -280px 17px 16px;
$question: -44px -120px 22px 40px;
$radio: -192px -173px 13px 13px;
$rss-blue: -112px -240px 16px 40px;
$rss-light: -128px -240px 16px 40px;
$rss-orange: -144px -240px 16px 40px;
$rss: -160px -240px 16px 40px;
$search: -176px -240px 16px 40px;
$star-blue: -192px -240px 16px 40px;
$star-heading-white: -80px 0px 40px 40px;
$star-heading: -80px -40px 40px 40px;
$star-light: -208px -240px 16px 40px;
$star-yellow: -224px -240px 16px 40px;
$star: -240px -240px 16px 40px;
$stats-blue: -256px -240px 16px 40px;
$stats-light: -287px 0px 16px 40px;
$stats: -287px -40px 16px 40px;
$tags: 0px -80px 40px 40px;
$thumb-down-voted: -287px -80px 16px 40px;
$thumb-down: -287px -120px 16px 40px;
$thumb-up-voted: -287px -160px 16px 40px;
$thumb-up: -287px -200px 16px 40px;
$tick-green: -287px -240px 16px 40px;
$tick-light: -303px 0px 16px 40px;
$tick: -303px -40px 16px 40px;
$tutorial: -303px -80px 16px 40px;
$tutorials: -40px -80px 40px 40px;
$unread: -65px -280px 16px 16px;
$view-blue: -303px -120px 16px 40px;
$view-light: -303px -160px 16px 40px;
$view: -303px -200px 16px 40px;
$warning: -66px -120px 22px 40px;
$zform-abbr: -81px -280px 16px 16px;
$zform-attention: -97px -280px 16px 16px;
$zform-blockcode: -113px -280px 16px 16px;
$zform-bold: -129px -280px 16px 16px;
$zform-center: -145px -280px 16px 16px;
$zform-chars: -161px -280px 16px 16px;
$zform-error: -177px -280px 16px 16px;
$zform-footnote: -193px -280px 16px 16px;
$zform-image: -209px -280px 16px 16px;
$zform-information: -225px -280px 16px 16px;
$zform-italic: -241px -280px 16px 16px;
$zform-key: -257px -280px 16px 16px;
$zform-link: -273px -280px 16px 16px;
$zform-math: -289px -280px 16px 16px;
$zform-neutre: 0px -296px 16px 16px;
$zform-ol: -16px -296px 16px 16px;
$zform-question: -32px -296px 16px 16px;
$zform-quote: -48px -296px 16px 16px;
$zform-right: -64px -296px 16px 16px;
$zform-secret: -80px -296px 16px 16px;
$zform-smilies: -96px -296px 16px 16px;
$zform-strike: -112px -296px 16px 16px;
$zform-sub: -128px -296px 16px 16px;
$zform-sup: -144px -296px 16px 16px;
$zform-table: -160px -296px 16px 16px;
$zform-title1: -176px -296px 16px 16px;
$zform-title2: -192px -296px 16px 16px;
$zform-title3: -208px -296px 16px 16px;
$zform-title4: -224px -296px 16px 16px;
$zform-titles: -240px -296px 16px 16px;
$zform-ul: -256px -296px 16px 16px;

@mixin sprite-width($sprite) {
  width: nth($sprite, 3);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 4);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 1);
  $sprite-offset-y: nth($sprite, 2);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite() {
  background-image: url('../images/sprite.png');
}

@mixin sprite-2x() {
  background-image: url('../images/sprite@2x.png');
  background-size: 319px 312px;
}
