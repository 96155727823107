.alert-box {
    position: relative;

    margin: 0 0 $length-16 2%;
    padding: $length-8 $length-32 $length-8 $length-16;

    background: $grey-500;
    color: $white;
    text-shadow: rgba($black, 0.2) 0 0 $length-2;

    &.alert-box-not-closable {
        padding-right: $length-16;
    }

    .alert-box-text {
        display: block;
        float: left;
    }

    .close-alert-box, .view-alert-box {
        display: block;
        position: absolute;
        top: $length-8;
        right: $length-16;
        height: $length-20;
        width: $length-20;
        text-indent: -9999px;
        text-decoration: none;
        background-color: transparent;
        line-height: 1.2;
        color: $white;

        &.ico-after:after {
            margin-top: $length-4;
        }
    }

    .close-alert-box-text {
        width: auto;
        text-indent: 0;
        top: $length-10;
    }

    .alert-box-title {
        margin: $length-6 0;
        padding: 0;

        font-size: $font-size-8;
        font-weight: normal;
    }

    &.info, // TEMP PATCH WITH DJANGO ALERTS
    &.success {
        background: $green-700;
    }
    &.error {
        background: $red-500;
    }
    &.alert, // TEMP PATCH WITH DJANGO ALERTS
    &.warning {
        background: $accent-800;
    }
    &.not-member {
        background: $true-white;
        color: $grey-800;
        text-shadow: none;
        border-bottom: $length-4 solid $grey-200;
    }

    &.ico-after {
        padding-left: $length-38;

        &:after {
            margin: $length-12 0 0 $length-12;
        }
    }

    h4,
    p {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    p {
        margin: 0;
    }

    a {
        color: $white;
    }

    .alert-box-btn {
        display: inline-block;
        background: $primary-600;
        text-decoration: none;
        padding: $length-8 $length-16;
        margin: $length-6 0;
        color: $white !important;

        &:hover,
        &:focus {
            background: $primary-500;
        }

        &.alert-box-btn-right {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
        }
    }

    &.empty {
        display: none;
    }
}

.content-wrapper .alert-box {
    margin: 0 0 $length-20;

    & + .not-member {
        margin-top: -$length-20;
    }
}

.opinion-alerts {
    .alert-box-text {
        float: none;
    }
}

@include tablet {
    .alert-box .alert-box-text {
        display: inline;
    }
    .topic-message .alert-box {
        // dunno if this 75px is important, will leave it in doubt
        // (the whole alerts CSS was rewritten in a future branch anyway)
        padding: $length-8 75px $length-8 $length-16;
    }
}

@include mobile {
    .alert-box {
        .alert-box-btn {
            &,
            &.alert-box-btn-right {
                position: relative;
                float: none;
                display: block;
                margin: $length-6 0 0;
                text-align: center;
            }
        }
    }
}
