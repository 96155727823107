.searchpage {
    .flexpage-header {
        padding-top: $length-48;
    }
    .pagination-top {
        margin-top: $length-32;
    }
}

@include until-desktop {
    .pagination-top {
        margin-top: $length-10;
    }
}
