@use "sass:math";

.header-dropdown {
    display: none;

    position: absolute;
    top: $header-height;
    left: 0;
    right: 0;

    margin: 0;
    padding: $length-10 2.5%;

    border-bottom: $length-2 solid $color-secondary;

    background-color: $color-header-hover;

    text-align: left;
    font-size: $font-size-10;

    z-index: 50;

    .dropdown-title {
        color: $white;
        font-weight: bold;
    }

    .dropdown-list .dropdown-title a {
        display: block;

        width: 95%;
        min-height: $length-24;

        line-height: 2;
        overflow: hidden;

        transition: padding-left $transition-duration ease, background-color $transition-duration ease;

        &:hover,
        &:focus {
            padding-left: $length-12;
            background-color: $primary-700;
        }
    }

    &.header-menu-dropdown .dropdown-list > li {
        @for $i from 1 through 4 {
            &:first-child:nth-last-child(#{$i}),
            &:first-child:nth-last-child(#{$i}) ~ li {
                width: math.div(100%, $i);
            }
        }
    }

    .dropdown-list {
        width: 100%;
        padding: 0;
        margin-top: $length-16;
    }

    .dropdown-list > li {
        width: 20%;
        float: left;

        &.dropdown-empty-message {
            color: $primary-000;
            text-align: center;
            line-height: $header-height;
            background: none !important;
        }

        ul {
            margin: 0 0 $length-10;
            padding: 0;

            li {
                position: relative;

                &.dropdown-list-element {
                    a {
                        color: $primary-000;
                        display: block;
                        width: 95%;
                        min-height: $length-24;
                        line-height: 2;
                        overflow: hidden;
                        transition: padding-left $transition-duration ease, background-color $transition-duration ease;

                        &:hover,
                        &:focus {
                            padding-left: $length-12;
                            background-color: $primary-700
                        }
                    }

                    &.staff-only a {
                        color: $color-staff-link;
                    }
                }
            }
        }
    }

    .dropdown-link-all {
        display: block;
        clear: both;
        text-align: center;
        height: $length-32;
        line-height: 2.2;
        border-top: $length-1 solid $primary-600;
        background-color: $color-header-hover;
        transition-property: color, background-color;

        &:first-child {
            border-top: 0 !important;
            border-bottom: $length-1 solid $primary-800;
        }

        &:hover,
        &:focus {
            color: $primary-000;
            background-color: $primary-700;
            border-top: $length-1 solid $primary-700;
        }
    }
}
.active + .header-dropdown {
    display: block;
}

@include tablet {
    .header-dropdown {
        box-shadow: 0 $length-6 $length-8 rgba($black, .3);
    }

    .header-right {
        .header-dropdown {
            width: $length-384;
            left: auto;
            padding: 0;

            .dropdown-list {
                max-height: $length-384;
                overflow-x: hidden;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: $length-10;
                    height: $length-10;
                }
                &::-webkit-scrollbar-track {
                    background-color: $primary-700;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: $primary-200;
                    border: 1px solid $primary-700;
                    transition: background-color $transition-duration ease;

                    &:hover {
                        background-color: $primary-200;
                    }
                    &:active {
                        background-color: $primary-100;
                    }
                }
            }

            &.my-account-dropdown {
                width: $length-384;
            }
        }
    }
}

@include mobile {
    html.dropdown-active {
        overflow: hidden;

        .page-container {
            width: 100%;
        }

        .main-container {
            display: none;
        }
    }

    .header-menu-dropdown {
        display: none !important;
    }

    .header-dropdown {
        width: 100%;
        top: 180px;
        bottom: 0;
        border-bottom: none;

        .dropdown-list {
            overflow: auto;
            position: absolute;
            top: 36px;
            bottom: 50px;
        }

        .dropdown-link-all {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 50px;
            line-height: 50px;
        }
    }
}

@include desktop {
    .header-dropdown {
        top: $header-height;
    }
}
