.stat-table {
    .level-1 {
        padding-left: $length-20;
    }

    .level-2 {
        padding-left: $length-38;
    }
}

 /* Style the tab */
.tab {
    border: $length-1 solid $grey-100;
    border-bottom: none;

    background-color: $grey-100;
    color: $color-primary;
}

/* Style the buttons that are used to open the tab content */
.tab span {
    display: inline-block;
    padding: $length-8 $length-16;

    cursor: pointer;
}

/* Change background color of buttons on hover */
.tab span:hover {
    background-color: $white;
}

/* Create an active/current tablink class */
.tab span.active {
    background-color: $white;
    font-weight: bold;
}

/* Style the tab content */
.tabcontent {
    /* Tab has to be displayed by default, otherwise Chart.js doesn't render the graph. */

    padding: $length-6 $length-12;

    border: $length-1 solid $grey-100;
    border-top: none;

    .stat-graph {
      width: 60%;
      margin: 0 auto;
    }
}
