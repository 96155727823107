.content-container,
.modals-container {
    form {
        width: 100%;

        &.content-wrapper {
            width: calc(100% - #{$length-20});
            margin: 0 $length-10;
        }

        p {
            position: relative;
        }

        &.btn-inline {
            width: auto;
            vertical-align: middle;
        }
    }

    .search-form {
        display: flex;
        align-items: stretch;

        input {
            margin-right: $length-10;
            height: initial;
        }
    }

    fieldset {
        border-top: $length-1 solid $grey-200;
        border-bottom: $length-4 solid $grey-200;
        background: $white;
        padding: 0 4%;

        legend {
            padding: 0 $length-10;
            border-top: $length-1 solid $grey-200;
            border-bottom: $length-4 solid $grey-200;
            background: $white;
        }
    }


    label {
        display: block;
        margin-top: $length-12;

        color: $grey-700;
        min-height: $length-32;
        font-size: $font-size-10;
        line-height: 2;

        cursor: pointer;

        .asteriskField {
            font-size: $font-size-9;
            color: $red-500;
            margin-left: $length-4;
            vertical-align: middle;
        }
    }

    .small-content-wrapper .asteriskField {
        display: none;
    }

    .form-error {
        display: block;
        font-size: $font-size-10;
        color: $red-500;
    }

    input,
    textarea {
        border: $length-1 solid $grey-200;

        &:focus {
            outline-color: $grey-400;
            border-color: $grey-400;
        }

        &.field-error,
        &:invalid {
            border-color: $red-700;

            &:focus {
                outline-color: $red-400;
                border-color: $red-400;
            }
        }

        &[disabled],
        .disabled {
            background: $grey-200 !important;
            color: $grey-700;
        }
    }
    input,
    textarea,
    button,
    .btn {
        -webkit-appearance: none;
        transition: color $transition-duration ease,
                    background $transition-duration ease;
    }

    input:not([type=submit]):not([type=reset]):not([type=radio]):not([type=checkbox]) {
        width: calc(98% - 2px);
        padding: 0 1%;
    }

    textarea {
        width: calc(98% - 2px);
        padding: $length-10 1%;
        font-family: $font-monospace;
        line-height: normal;
    }

    input,
    button,
    .btn {
        display: block;
        height: $length-38;

        &.ico-after {
            padding-left: $length-32;

            &:after {
                margin: $length-12 0 0 $length-10;
            }
        }
    }
    [type=submit],
    button:not(.link),
    .btn {
        position: relative;

        margin-left: $length-2;
        padding: 0 $length-16;

        border: none;
        outline: none;

        height: $length-38;
        line-height: 2.5;

        color: $grey-200;
        text-decoration: none;

        cursor: pointer;

        &.submitted {
            color: $grey-700;

            // Animation loading on submit buttons
            .line-loading {
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 0;
                height: $length-1;
                background: $grey-700;
                animation: lineLoading linear 1s infinite;
            }
        }

        &:not(.btn-holder){
            float: right;
        }
    }

    [type=submit]:not(.link),
    .btn-submit:not(.link) {
        color: $white;
        background: $color-success;

        &:not([disabled]):hover,
        &:not([disabled]):focus,
        &:not(.disabled):hover,
        &:not(.disabled):focus {
            background: $color-success-hover;
        }

        &.disabled.submitted {
            color: $color-success;

            .line-loading {
                background: $color-success;
            }
        }
    }
    .btn-cancel:not(.link) {
        color: $true-white;
        background: $color-danger;

        &:not([disabled]):hover,
        &:not([disabled]):focus,
        &:not(.disabled):hover,
        &:not(.disabled):focus {
            background: $color-danger-hover;
        }

        &.disabled.submitted {
            color: $color-danger;

            .line-loading {
                background: $color-danger;
            }
        }
    }

    @mixin addBtnColorProperties($bg, $color, $bg-hover, $color-hover, $bg-loading) {
        &:not(.link) {
            background: $bg;
            color: $color;

            &:not([disabled]):hover,
            &:not([disabled]):focus,
            &:not(.disabled):hover,
            &:not(.disabled):focus {
                background: $bg-hover;
                color: $color-hover;
            }

            &.disabled.submitted {
                color: $color;

                .line-loading {
                    background: $bg-loading;
                }
            }
        }
    }

    .btn-grey {
        @include addBtnColorProperties($grey-100, $grey-700, $grey-200, $grey-800, $grey-400);
    }

    .btn-inline {
        display: inline-block;
    }
    .btn-no-float {
        float: none !important;
        vertical-align: middle;
    }
    [disabled],
    .disabled {
        cursor: default !important;
        background: $grey-100 !important;

        &:not(.submitted){
            color: $grey-300 !important;
        }
    }

    .btn.is-social-login {
        float: none;

        display: flex;
        align-items: center;

        width: 50%;
        height: $length-38;

        @include mobile {
            width: 80%;
        }

        margin: $length-8 auto;

        border: 1px solid transparent;

        transition: $transition-duration ease-in-out;
        transition-property: background-color border-color;

        &:not(:first-of-type) {
            margin-top: $length-16;
        }

        img {
            width: $length-24;
            height: $length-24;
        }

        span {
            flex: 2;
            text-align: center;
            font-size: $font-size-9;

            line-height: 1.2;
        }

        &.is-facebook {
            background-color: $color-brand-facebook;
            border-color: $color-brand-facebook;
            color: white;

            &:hover {
                background-color: $color-brand-facebook-hover;
                border-color: $color-brand-facebook-hover;
            }
        }

        &.is-google {
            background-color: white;
            border-color: $color-brand-google;
            color: $grey-700;

            &:hover {
                border-color: $color-brand-google-hover;
                color: $grey-900;
            }
        }
    }
}

.content-container {
    .btn-holder,
    .buttonHolder /* specifix for crispy */ {
        margin-top: $length-24;
        min-height: $length-38;
    }
}

.main-container,
.modals-container {
    input[type=radio],
    input[type=checkbox] {
        float: left;

        position: relative;
        top: -$length-2;

        margin-right: $length-6;

        height: $length-18;
        width: $length-18;

        border: $length-1 solid $grey-300;
        background: $white;

        cursor: pointer;

        transition: none;

        &:after {
            content: '';

            position: absolute;
            top: $length-1;
            left: $length-1;
            bottom: 0;
            right: 0;

            display: block;
            opacity: 0;

            @include sprite();
        }

        &:checked:after {
            opacity: 1;
        }
    }
    input[type=radio] {
        border-radius: 50%;
    }
    input[type=radio]:after {
        @include sprite-position($radio);
    }
    input[type=checkbox]:after {
        @include sprite-position($check);
    }

    // TEMP STYLES TO PATCH TEMP TEMPLATE PACK MIX
    .checkbox,
    .radio {
        margin: 0;
        padding: $length-10 0;
        height: 100%;

        &:not(:first-child) {
            padding-top: 0;
        }
        &:not(:last-child) {
            padding-bottom: 0;
        }

        input {
            margin-top: $length-8;
        }
    }

    .controls .radio {
        padding-top: $length-4;
        padding-bottom: 0;
    }

    // used in the "new content" page
    .checkbox-new-content {
        padding: 0;
    }
}

@include desktop {
    .content-container,
    .modals-container {
        form.content-wrapper {
            margin: 0;
            width: 100%;
        }
    }
}


@keyframes lineLoading {
    0% {
        width: 0%;
        left: 0;
        right: inherit;
    }
    49% {
        left: 0;
        right: inherit;
    }
    50% {
        width: 100%;
        left: inherit;
        right: 0;
    }
    100% {
        left: inherit;
        right: 0;
    }
}
