#exports-modal {
    width: 500px;

    section.exports {
        margin-top: $length-32;
        margin-bottom: $length-38;

        &.is-empty {

            p {
                margin: 0;
                text-align: center;
            }
        }

        article.export {
            display: flex;
            flex-direction: row;
            align-items: center;

            margin: 0 $length-10 $length-16 0;

            header {
                flex: 2;
                margin-left: $length-38;

                position: relative;

                &:before {
                    content: '';

                    position: absolute;
                    left: -$length-32;
                    top: calc(50% - #{$length-8});

                    width: $length-16;
                    height: $length-16;

                    background-repeat: no-repeat;
                }

                &:not(.is-requested):not(.is-running):before {
                    @include sprite();
                }

                h4 {
                    margin: 0;
                    font-size: $font-size-9;
                }

                p {
                    font-size: $font-size-10;
                    margin-bottom: 0;

                    color: $grey-500;

                    a {
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            &.is-requested, &.is-running {
                header:before {
                    content: '';

                    display: block;
                    left: -2.8rem; // Alignment with other icons

                    width: $length-8;
                    height: $length-8;

                    background-color: $color-primary;
                    border-radius: $radius-round;
                }

                &.is-running header:before {
                    animation: pulse 2s infinite;
                }

                footer {
                    color: $color-primary;
                }
            }

            &.is-success {
                header:before {
                    @include sprite-position($tick-green);
                }

                footer {
                    color: $color-success;
                }
            }

            &.is-failure {
                header:before {
                    @include sprite-position($cross-red)
                }

                footer {
                    color: $color-danger;
                }
            }
        }
    }

    footer {
        h3 {
            margin-bottom: $length-10;
        }

        p {
            margin-bottom: $length-16;
        }

        button.btn {
            float: none;

            margin: $length-4 $length-10 $length-16;
            width: calc(100% - #{$length-20});

            &[disabled] {
                background-color: transparent !important;
                color: $grey-500 !important;
            }
        }
    }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
