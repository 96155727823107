// Border radius to use if possible.
// You can see previews of them at
// https://zestedesavoir.github.io/normes-graphiques/#arrondis

$radius-1: .4rem;
$radius-2: .6rem;
$radius-3: .8rem;
$radius-4: 1.2rem;
$radius-5: 1.6rem;
$radius-round: 19042014px;
