.ir {
    background-color: transparent;
    border: 0;
    overflow: hidden;
    *text-indent: -9999px;
}

.ir:before {
    content: "";
    display: block;
    width: 0;
    height: 150%;
}

.hidden {
    display: none !important;
    visibility: hidden;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.invisible {
    visibility: hidden;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

hr.clearfix {
    clear: both;
    height: 0;
    border: none;
}

.unstyled-list {
  list-style: none;
  padding-left: 0;
}

// Hidden on mobile
.desktop,
.wide {
    display: none;
}

// Hidden on desktop
.until-desktop,
.until-wide {
    display: none;
}

@include wide {
    .wide {
        display: inline;
    }
    table .wide {
        display: table-cell;
    }
}

@include desktop {
    .desktop {
        display: inline;
    }
}

@include until-desktop {
    .until-desktop {
        display: inline;
    }
}

@include until-wide {
    .until-wide {
        display: inline;
    }
}
