.notification-list {
    margin: $length-48 0 !important;

    .notification {
        position: relative;

        display: flex;
        align-items: baseline;

        border-top: $length-1 solid $true-white;
        border-bottom: $length-1 solid $grey-200;
        border-left: $length-1 solid transparent;

        overflow: hidden;

        &:first-child {
            border-top: $length-1 solid $grey-200;
        }

        &:before {
            content: " ";

            position: absolute;

            display: block;
            width: $length-2;
            height: 100%;

            background: transparent;
        }

        &.unread:before {
            background: $primary-400;
        }

        &:nth-child(2n){
            background: none;
        }

        &.unread {
            background: $true-white;

            .notification-description .notification-title {
                font-weight: bold;
            }
        }

        &:hover,
        &.active {
            &:before {
                width: $length-6;
                background: $primary-400;
            }
        }

        &.selected {
            background-color: $primary-000;
        }
    }

    a {
        text-decoration: none;
        color: $primary-300;

        &:hover,
        &:focus {
            color: $primary-300;
            text-decoration: underline;
            outline: none;
        }
    }

    .notification-infos,
    .notification-description,
    .notification-last-answer {
        display: block;

        margin: 0;
        padding: $length-4 0;
    }

    .notification-infos {
        width: 5%;

        .ico-after {
            display: block;
            text-indent: -9999px;

            &:after {
                margin: $length-4 0 0 15px;
            }
        }
    }

    .notification-description {
        width: 60%;

        .notification-title-link {
            display: block;

            &:hover,
            &:focus {
                text-decoration: none;

                .topic-title {
                    text-decoration: underline;
                }
            }
        }

        .notification-title {
            display: block;
            margin: 0 !important;
            padding: 0;
            font-size: $font-size-9;
            font-weight: normal;
        }
    }

    .notification-last-answer {
        width: 35%;
    }
}

@include desktop {
    .notification-list {
        .notification-last-answer-short-date {
            display: none;
        }
    }
}

@include until-desktop {
    .notification-list {
        .notification {
            background: none !important;

            .notification-last-answer {
                width: 30%;
                text-align: right;

                .notification-last-answer-short-date {
                    font-size: $font-size-10;
                }

                .notification-last-answer-long-date {
                    display: none;
                }
            }
        }
    }
}

@include mobile {
    .notification-list {
        .notification-infos .ico-after:after {
            margin: $length-4 0 0 $length-2;
        }
        .notification {
            background: none !important;

            .notification-last-answer {
                width: 20%;
            }
        }
    }
}
