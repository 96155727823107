$transition-duration: .15s;

@import "colors";
@import "typography";
@import "lengths";
@import "shadows";
@import "radius";
@import "media-queries";

$modal-margin: $length-24;
$header-height: $length-64;
