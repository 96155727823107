/* Zeste de Savoir syntax theme */
/* Based on a11y-light theme by ericwbailey: https://github.com/highlightjs/highlight.js/blob/35cd46312bf3e898471617c47d52f9528fedd763/src/styles/a11y-light.css */
/* Itself based on the Tomorrow Night Eighties theme: https://github.com/isagalaev/highlight.js/blob/master/src/styles/tomorrow-night-eighties.css */
/* @author: Amaury Carrade */

// Comment
.hljs-comment,
.hljs-quote {
  color: $grey-500;
}

// Red
.hljs-variable,
.hljs-template-variable,
.hljs-tag,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class,
.hljs-regexp,
.hljs-deletion {
  color: $red-600;
}

// Orange
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-params,
.hljs-meta,
.hljs-link {
  color: $accent-900;
}

// Yellow
.hljs-attribute,
.hljs-selector-pseudo {
  color: $accent-800;
}

// Green
.hljs-string,
.hljs-symbol,
.hljs-bullet,
.hljs-addition,
.hljs-selector-attr {
  color: $green-700;
}

// Blue
.hljs-title,
.hljs-section {
  color: $primary-600;
}

.hljs-number {
  color: $blue-400;
}

// Purple
.hljs-keyword,
.hljs-selector-tag {
  color: $purple-800;
}

// Emphasis on these
.hljs-attribute,
.hljs-keyword,
.hljs-selector-class,
.hljs-selector-tag,
.hljs-selector-id,
.hljs-string {
  font-weight: 700;
}

// The whole code
.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;

  background: $true-white;
  color: $black;
}

// Markdown
.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

@media screen and (-ms-high-contrast: active) {
  .hljs-addition,
  .hljs-attribute,
  .hljs-built_in,
  .hljs-builtin-name,
  .hljs-bullet,
  .hljs-comment,
  .hljs-link,
  .hljs-literal,
  .hljs-meta,
  .hljs-number,
  .hljs-params,
  .hljs-string,
  .hljs-symbol,
  .hljs-type,
  .hljs-quote {
        color: highlight;
    }

    .hljs-keyword,
    .hljs-selector-tag {
        font-weight: bold;
    }
}
