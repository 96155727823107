@media only screen and (-webkit-min-device-pixel-ratio: 1.3),
       only screen and (min--moz-device-pixel-ratio: 1.3),
       only screen and (-o-min-device-pixel-ratio: 4/3),
       only screen and (min-device-pixel-ratio: 1.3),
       only screen and (min-resolution: 192dpi),
       only screen and (min-resolution: 2dppx) {

    .header-container .header-logo-link {
        background-image: url('../images/logo@2x.png');
    }

    .ico,
    .ico-after:after,
    .breadcrumb ol li:not(:last-child):after {
        @include sprite-2x();
    }

    .main-container,
    .modals-container {
        input[type=radio]:after,
        input[type=checkbox]:after {
            @include sprite-2x();
        }
    }

    .home .home-search-box::before {
        background-image: url('../images/home-clem@2x.png');

        @at-root {
            body.vc-clem-christmas.home .home-search-box::before {
                background-image: url('../images/home-clem-christmas@2x.png');
            }

            body.vc-clem-halloween.home .home-search-box::before {
                background-image: url('../images/home-clem-halloween@2x.png');
            }
        }
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.3) and (max-width: $desktop - 1px),
       only screen and (min--moz-device-pixel-ratio: 1.3) and (max-width: $desktop - 1px),
       only screen and (-o-min-device-pixel-ratio: 4/3) and (max-width: $desktop - 1px),
       only screen and (min-device-pixel-ratio: 1.3) and (max-width: $desktop - 1px),
       only screen and (min-resolution: 192dpi) and (max-width: $desktop - 1px),
       only screen and (min-resolution: 2dppx) and (max-width: $desktop - 1px) {

    .enable-mobile-menu .mobile-menu-hide .page-container .mobile-menu-btn:after {
        @include sprite-2x();
    }

    .page-container .header-logo-link {
        background-image: url('../images/logo-mobile@2x.png') !important;
    }
}
