.taglist {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    list-style: none;

    margin: 0 0 $length-16 0;
    padding: 0;

    height: $length-32;
    line-height: $length-32;

    li {
        a {
            display: block;

            margin-left: $length-1;
            padding: 0 $length-10;

            background: $color-header-hover;
            color: $white;

            text-decoration: none;

            transition-property: color, background, border;
            transition-timing-function: ease;
            transition-duration: $transition-duration;

            &:hover,
            &:focus {
                border-bottom: $length-1 solid $color-header-hover;
                background: $grey-000;
                color: $color-header-hover;
            }
        }
    }
}

.content-tags-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &:after {
        content: '';
        display: block;
        flex: 20;
    }
}

.content-tag {
    flex: auto;
    margin: 0 $length-6 $length-20;
    white-space: nowrap;

    a {
        display: block;
        padding: $length-8 $length-16;

        border: solid $length-1 $grey-200;
        background-color: $grey-000;

        color: $grey-800;
        text-decoration: none;

        &, .tag-count {
            transition-property: color, background, border, outline;
            transition-timing-function: ease;
            transition-duration: $transition-duration;
        }

        .tag-count {
            color: $grey-500;
        }

        &:hover, &:focus {
            color: $grey-000;
            background-color: $grey-400;
            border-color: $grey-400;
            outline: none;

            .tag-count {
                color: $grey-100;
            }
        }
    }
}
