/* Temp fix to #2667 ; see: https://github.com/zestedesavoir/zds-site/issues/2667 */

.tutorial-help-item {
    display: flex;

    min-height: $length-64;
    padding: $length-20 2%;

    border-bottom: $length-1 solid $grey-100;

    color: $grey-600;
    font-weight: normal;

    &:nth-child(2n+1) {
        background-color: rgba($true-white, .8);
    }

    p {
        margin: 0;
    }

    .tutorial-title {
        margin: 0;
        padding: 0;

        width: 100%;
        height: 27px;

        font-size: $font-size-7;

        color: $grey-600;
        font-weight: normal;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    a {
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    .tutorial-categories {
        margin: 0 0 $length-6;
        padding: 0;
        color: $accent-800;

        a {
            color: $accent-800;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }

    .tutorial-illu {
        display: block;

        width: $length-64;
        height: $length-64;

        margin: $length-16 $length-16 $length-4 $length-4;

        overflow: hidden;
    }

    .tutorial-infos {
        margin: $length-8;
        margin-left: 0;

        &.no-illu {
            margin-left: 0;
        }
    }

    .tutorial-help {
        margin: $length-12 0 0 0;

        a:hover {
            text-decoration: none;
        }

        img.light {
            opacity: 0.2;

            &:hover,
            &:focus {
                opacity: 0.5;
            }
        }
    }
}
