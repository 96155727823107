.content-container {
    form {
        .alert-box {
            margin: 0;
            padding: $length-2 inherit;
            padding-right: $length-38;
        }

        .editor-toolbar {
            border: 0;
            background-color: $color-body-background;

            /* Let the toolbar stay on screen when writing long texts */
            /* Disabled on mobile or when the editor is fullscreen */
            @include tablet {
                &:not(.fullscreen) {
                    position: sticky;
                    z-index: 2;

                    top: 0;
                    @at-root body.has-top-banner & {
                        top: $length-24;
                    }

                    border-bottom: $length-1 solid $grey-200;
                    ~ .CodeMirror {
                        border-top: 0;
                    }
                }
            }

            &.fullscreen {
                z-index: 22; /* needs to be above #very-top-banner (_very-top-banner.scss) */
            }

            &.disabled-for-textarea-mode {
                button {
                    &.disable-for-textarea-mode {
                        opacity: .6;
                        pointer-events: none;
                    }
                }
            }

            button:not(.link) {
                display: inline;

                float: unset;
                padding: 0;

                height: $length-32;

                border: $length-1 solid transparent;
                border-radius: $radius-1;

                line-height: 0;
                color: $primary-600;
                cursor: pointer;
                vertical-align: middle;

                white-space: normal;

                &.emoji {
                    zoom: 100%;
                    width: 48px;
                    height: 32px;

                    &.smile {
                        background: url(/static/smileys/svg/smile.svg) no-repeat;
                    }
                    &.heureux {
                        background: url(/static/smileys/svg/heureux.svg) no-repeat;
                    }
                    &.clin {
                        background: url(/static/smileys/svg/clin.svg) no-repeat;
                    }
                    &.langue {
                        background: url(/static/smileys/svg/langue.svg) no-repeat;
                    }
                    &.rire {
                        background: url(/static/smileys/svg/rire.svg) no-repeat;
                    }
                    &.unsure {
                        background: url(/static/smileys/svg/unsure.svg) no-repeat;
                    }
                    &.triste {
                        background: url(/static/smileys/svg/triste.svg) no-repeat;
                    }
                    &.huh {
                        background: url(/static/smileys/svg/huh.svg) no-repeat;
                    }
                    &.b {
                        background: url(/static/smileys/svg/b.svg) no-repeat;
                    }
                    &.mechant {
                        background: url(/static/smileys/svg/mechant.svg) no-repeat;
                    }
                    &.blink {
                        background: url(/static/smileys/svg/blink.svg) no-repeat;
                    }
                    &.hihi {
                        background: url(/static/smileys/svg/hihi.svg) no-repeat;
                    }
                    &.siffle {
                        background: url(/static/smileys/svg/siffle.svg) no-repeat;
                    }
                    &.ange {
                        background: url(/static/smileys/svg/ange.svg) no-repeat;
                    }
                    &.angry {
                        background: url(/static/smileys/svg/angry.svg) no-repeat;
                    }
                    &.diable {
                        background: url(/static/smileys/svg/diable.svg) no-repeat;
                    }
                    &.magicien {
                        background: url(/static/smileys/svg/magicien.svg) no-repeat;
                    }
                    &.ninja {
                        background: url(/static/smileys/svg/ninja.svg) no-repeat;
                    }
                    &.pinch {
                        background: url(/static/smileys/svg/pinch.svg) no-repeat;
                    }
                    &.pirate {
                        background: url(/static/smileys/svg/pirate.svg) no-repeat;
                    }
                    &.pleure {
                        background: url(/static/smileys/svg/pleure.svg) no-repeat;
                    }
                    &.rouge {
                        background: url(/static/smileys/svg/rouge.svg) no-repeat;
                    }
                    &.soleil {
                        background: url(/static/smileys/svg/soleil.svg) no-repeat;
                    }
                    &.waw {
                        background: url(/static/smileys/svg/waw.svg) no-repeat;
                    }
                    &.zorro {
                        background: url(/static/smileys/svg/zorro.svg) no-repeat;
                    }
                    &.popcorn {
                        background: url(/static/smileys/svg/popcorn.svg) no-repeat;
                    }
                    &.bounce {
                        background: url(/static/smileys/svg/bounce.svg) no-repeat;
                    }
                }
                &.bloc_information {
                    color: #03a9f4;
                }
                &.bloc_question {
                    color: #7e57c2;
                }
                &.bloc_warning {
                    color: #ffcc80;
                }
                &.bloc_error {
                    color: #ef9a9a;
                }
            }

            button.blocMenu {
                border-color: $color-body-background;
                background-image: linear-gradient(to right bottom, $color-body-background 0px, $color-body-background 84%, $grey-800 50%, $grey-800 100%);
            }

            .easymde-dropdown-content {
                background-color: $white;
            }

            button.active,
            button:hover {
                border: $length-1 solid transparent;
                background: $white;
                border-color: $primary-100;
            }
        }

        textarea.textarea-multivers {
            margin: 0 !important;
            padding: $length-14;
            line-height: 2;
            box-sizing: border-box;
            display: block;
            width: 100%;
        }

        .zdsicon {
            & > i {
                display: inline-block;

                // Icon's size
                height: 17px;
                width: 17px;

                background-size: 100%;
            }

            &.zi-grammalecte > i {
                background-image: url(../images/editor/svg/grammalecte_H084561.svg);
            }
        }

        .CodeMirror {
            width: 100%;

            pre {
                font-family: $font-monospace;
            }
        }

        .editor-preview {
            background-color: $white;
        }
    }
}
