#topic-result-container {
	background-color: $true-white;
	border: 1px solid $grey-200;

	ul {
		margin: 0;
		padding: 0;

		font-size: $font-size-10;
		list-style: none;

		li {
			padding: $length-1 $length-10;
			border-bottom: solid 1px $grey-200;

			&.active, &:hover {
				background-color: $grey-000;

                &.neither {
				    background-color: transparent;
                }
			}

			&:last-child {
				border-bottom: none;
			}
		}
	}
}
