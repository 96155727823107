.markdown-help {
    position: relative;

    margin-bottom: $length-4;
    padding: $length-16;
    padding-right: $length-20;

    min-height: $length-24;

    border-bottom: $length-1 solid $grey-200;
    background: $grey-000;

    .close-alert-box {
        position: absolute;
        top: $length-4;
        right: $length-4;

        display: block;

        width: $length-20;
        height: $length-20;

        background-color: transparent;

        text-decoration: none;
        text-indent: -9999px;
        line-height: 2.2;

        color: $white;

        &.ico-after:after {
            margin-top: $length-4;
        }
    }

    .message-content:first-child &:first-child {
        @include desktop {
            margin-top: $length-14;
        }
    }
}
