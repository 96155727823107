$content-width: 1145px;

.flexpage {
    .main {
        display: block;

        width: auto;
        height: auto;

        margin: 0;
        padding: 0;
    }

    #content {
        margin: 0;
        padding: 0;

        width: 100%;
    }

    .sub-header {
        display: none;
    }

    .flexpage-wrapper {
        max-width: $content-width;
        margin: 0 auto $length-20;
    }

    .flexpage-header {
        margin-bottom: $length-20;
        border-bottom: solid $length-1 $true-white;

        // fallback for older browser
        background-color: $primary-500;
        background: $primary-500 radial-gradient(at top, rgba($true-white, 0.1), transparent 60%);
    }

    .flexpage-column {
        display: flex;
        margin-left: -$length-20;
        margin-bottom: $length-24;

        section {
            flex: 0 0 auto;
            width: calc(50% - #{$length-20});
            margin-left: $length-20;
        }
    }

    section > h2:not(.inline) {
        display: flex;

        span {
            flex: 2;
        }

        .btn {
            float: none;
        }
    }

    .flexpage-title-tool {
        display: flex;
        flex-direction: row;

        padding: $length-48 0;
        font-size: $font-size-7;
        font-weight: 100;

        .picto {
            align-self: center;

            .picto-hexagon {
                position: relative;

                // FIXME Magic values + floating-point pixels should not be used
                width: 104px;
                height: 60.04px;
                margin: 30.02px 50px;

                background: $primary-700;

                &:before, &:after {
                    z-index: 0;
                    content: '';
                    position: absolute;
                    width: 0;
                    border-left: 52px solid transparent;
                    border-right: 52px solid transparent;
                }

                &:before {
                    bottom: 100%;
                    left: 0;
                    border-bottom: 30.02px solid $primary-700;
                }

                &:after {
                    top: 100%;
                    left: 0;
                    width: 0;
                    border-top: 30.02px solid $primary-700;
                }

                img {
                    position: absolute;
                    top: -$length-20;
                    left: $length-2;
                }
            }
        }

        .avatar {
            flex: 4;
            align-self: start;

            text-align: center;

            figure {
                display: flex;
                text-align: center;

                margin: 0;
                padding: 0;

                width: $length-192;
                height: $length-192;

                border-radius: $radius-1;

                background-color: $primary-700;

                img {
                    margin: auto;
                    max-width: $length-192;
                    max-height: $length-192;
                }
            }
        }

        .actions {
            flex: 4;

            button, .btn-ico {
                width: 100%;

                margin-top: $length-6;
                margin-bottom: $length-6;
                padding: 0;

                background: $primary-600;

                color: $white;
                text-align: center;
                text-transform: uppercase;
                font-size: $font-size-10;
                font-weight: 400;

                #subscriber_count, #subscriber_count_combined {
                    margin-left: $length-10;
                }

                &:hover,
                &:focus {
                    color: $white !important;
                    background: $primary-700 !important;
                }

                &[disabled],
                &[disabled]:hover {
                    color: $white !important;
                    background: $primary-600 !important;
                }
            }

            .buttons-combined {
                display: flex;
                flex-direction: row;

                width: 100%;

                .sub-button {
                    flex: 2;

                    &:not(:first-child) {
                        margin-left: $length-4;
                    }

                    &.primary-button {
                        flex: 8;
                    }

                    &.static-button {
                        @extend .btn-ico;

                        padding-top: $length-4;

                        &.statistic {
                            font-size: $font-size-8;
                        }

                        &:hover,
                        &:focus {
                            background: $primary-600 !important;
                        }
                    }

                    // FIXME too specific
                    #follow_text {
                        padding-left: $length-12;
                    }
                }
            }
        }

        .title {
            flex: 12;
            align-self: flex-start;

            display: block;
            color: white;
            font-size: $font-size-6;

            overflow: hidden;

            .line {
                display: block;
                line-height: 1.5;

                .line-item {
                    display: inline-block;

                    a {
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            h1 {
                display: block;
                margin: 0;
                padding: 0;

                border: none;

                color: inherit;

                font-size: $font-size-3;
                line-height: 1;

                overflow: hidden;
                text-overflow: ellipsis;
            }

            h2 {
                display: inline-block;
                margin: 0;
                padding: 0;

                border: none;

                vertical-align: bottom;

                font-size: inherit;
                line-height: inherit;

                color: inherit;
            }

            .option {
                display: inline-block;
                margin: 0 $length-16 0 0;
            }

            a {
                display: inline;
                color: $white;
                text-decoration: none;
            }

            .has-separator {
                position: relative;

                padding-left: $length-24;
                padding-right: $length-6;

                &:after {
                    content: '';

                    position: absolute;
                    top: $length-12;
                    left: 0;

                    width: $length-10;
                    height: $length-10;

                    border-color: rgba($primary-000, .8);
                    border-style: solid;
                    border-width: $length-2 $length-2 0 0;

                    transform: rotate(45deg);
                }
            }
        }

        .aside {
            margin-top: $length-10;
            margin-right: $length-20;

            .search {
                display: flex;
                flex-direction: row;

                height: $length-48;

                background: $true-white;

                label {
                    height: $length-48;

                    margin: 0;
                    padding: 0 $length-16;

                    font-size: $font-size-6;
                    line-height: 2;
                }

                input {
                    flex: 1 1 auto;

                    width: initial !important;
                    min-width: 0;
                    height: $length-48;

                    margin: 0;
                    padding: 0 $length-16;

                    border: none;

                    font-weight: 100;
                    line-height: $length-48;
                }

                button {
                    width: $length-48;
                    height: $length-48;

                    background: $true-white;
                    line-height: $length-48;

                    &:after {
                        margin: $length-16 !important;
                        @include sprite-position($search);
                        @include sprite-width($search);
                        @include sprite-height($search);
                    }

                    &:hover, &:focus {
                        background: $grey-200 !important;
                    }
                }
            }

            .under-search {
                font-size: $font-size-8;

                p, ul {
                    margin: 0;
                    padding: $length-4 0;
                }

                ul li {
                    display: inline-block;
                    list-style-type: none;

                    &:not(:first-child) {
                        margin-left: $length-12;

                        &:before {
                            content: "—";
                            position: relative;
                            left: -$length-6;
                            top: -$length-1;
                        }
                    }
                }
            }
        }
    }

    .alert-box {
        margin-left: 0;
    }
}

@include desktop {
    .flexpage-title-tool .actions {
        & ~ .title,
        & ~ .aside {
            margin-right: $length-192;
        }
    }
}

@include until-desktop {
    .flexpage {
        .flexpage-wrapper {
            padding: $length-20 $length-10;
        }

        .flexpage-column {
            display: block;
            margin-left: 0;

            section {
                width: 100%;
                margin-left: 0;
            }
        }

        .flexpage-title-tool {
            padding: $length-16 $length-24;

            .picto,
            .avatar,
            .actions {
                display: none;
            }

            .aside,
            .title {
                max-width: 100%;
            }
        }
    }
}

@include mobile {
    .flexpage {
        .flexpage-title-tool {
            padding: $length-16 0;

            .aside {
                margin-right: 0;

                .search label {
                    padding-right: $length-6;
                }
            }
        }
    }
}
