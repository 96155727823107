.page-footer {
    height: $length-38;

    border-top: $length-4 solid $color-secondary;
    background: $color-primary;
    font-size: $font-size-10;

    .wrapper {
        display: flex;
        align-items: center;

        height: 100%;
    }

    .copyright, .links {
        flex-shrink: 0;
        flex-grow: 5;
        flex-basis: 0;
    }

    .copyright {
        margin: 0;
        padding: 0 $length-10;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &,
        a {
            color: $primary-100;
        }

        a:hover,
        a:focus {
            color: $white;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        white-space: nowrap;

        &.links {
            text-align: right;
        }

        &.links li {
            display: inline-block;
            margin: 0 $length-10;

            a {
                text-decoration: none;
                color: $white;
                border-bottom: $length-1 solid transparent;

                &:hover,
                &:focus {
                    border-bottom-color: $color-secondary;
                }
            }
        }

        &.social {
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: auto;
            text-align: center;

            li {
                margin: $length-2 #{$length-10};
                display: inline-block;

                a {
                    display: block;
                    height: $length-10;
                    width: $length-16;

                    &:after {
                        opacity: .6;
                    }

                    &:hover {
                        border-bottom-color: transparent;

                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    @include until-desktop {
        height: auto;

        padding: $length-12 $length-2;

        text-align: center;

        .wrapper {
            flex-direction: column;

            > *:not(:last-child) {
                margin-bottom: $length-8;
            }

            .social {
                -ms-flex: none; // fix IE ≥ 10
            }
        }

        .copyright, .social {
            flex-basis: auto;
            flex-shrink: 0;
        }

        ul {
            white-space: initial;

            &.links {
                text-align: inherit;
            }

            li {
                margin: 0 $length-6;
            }
        }
    }
}
