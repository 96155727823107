.mobile-menu,
.mobile-menu-btn {
    display: none;
}

@include desktop {
    .mobile-menu-only {
        display: none;
    }
}

@include until-desktop {
    .js .page-container {
        position: relative;

        transform: translate3d(0, 0, 0);
        z-index: 10;
    }

    .js .mobile-menu {
        position: fixed;

        display: block;

        width: 90%;
        height: 100%;

        padding-bottom: $length-4;
        background: $grey-900;

        overflow-x: hidden;
        overflow-y: auto;

        transform: translate3d(-20%, 0, 0);
        user-select: none;
        z-index: 1;

        .search {
            position: relative;
            top: 0;
            left: 0;

            display: flex;

            width: 100%;
            height: $length-48;

            form {
                flex: 1;
            }

            input {
                padding: $length-10 $length-14;

                width: 100%;
                height: 100%;
                box-sizing: border-box;

                background-color: $grey-800;
                color: $grey-000;

                font-size: $font-size-9;

                &:hover,
                &:focus {
                    padding-bottom: $length-6;
                    border-bottom: $length-4 solid $grey-600;
                    background-color: $grey-800;
                }
            }

            button {
                display: none;
            }

            .search-more {
                width: $length-48;
                height: $length-48;

                background-color: $grey-700;
                color: $grey-000;

                line-height: 1.5;
            }
        }

        .mobile-menu-bloc,
        .mobile-menu-link {
            line-height: 2.5;
            text-indent: 0;
        }

        .mobile-menu-bloc {
            margin: $length-12 $length-16;

            @at-root html.js body.has-top-banner .mobile-menu .mobile-menu-bloc:last-child {
                // The very-top banner shifts the menu down, hiding the latest button.
                // Add height of the banner so we can see the last button
                // Added twice to account for frequent two-lines banner on
                // mobile.
                margin-bottom: $length-12 + $length-24 * 2;
            }

            ul,
            li {
                margin: 0;
                padding: 0;
            }

            .mobile-menu-link {
                margin: 0;
                width: 100%;

                &.disabled {
                    opacity: .5;
                }
            }

            &:not(.mobile-show-ico) .ico-after:after {
                display: none;
            }

            &[data-title]:before {
                content: attr(data-title);

                display: block;
                height: $length-32;

                padding-bottom: $length-4;
                border-bottom: $length-2 solid $grey-400;

                font-size: $font-size-10;
                font-weight: bold;
                text-transform: uppercase;

                color: $grey-400;
            }

            &.mobile-show-ico {
                .ico-after {
                    padding-left: $length-32;
                    width: calc(100% - #{$length-32});

                    &:after {
                        top: $length-12;
                        left: $length-2;
                    }
                }

                .icon {
                    display: inline-block;

                    width: $length-16;
                    height: $length-16;

                    margin: $length-8;
                    padding-left: $length-6;

                    line-height:1.5;

                    &:after {
                        top: 0;
                        left: 0;
                    }
                }
            }
        }

        .mobile-menu-link {
            display: block;

            height: $length-38;

            padding: 0;
            border: none;

            background: none;

            font-size: $font-size-9;
            line-height: 2.5;

            text-decoration: none;
            text-align: left;

            color: $grey-200;

            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            &.mobile-menu-sublink {
                margin: 0 0 0 $length-20;
            }

            &.mobile-menu-bloc[data-title] {
                height: 80px;
            }

            &.mobile-menu-bloc:not([data-title]) {
                margin-bottom: 0;
            }

            &:not(:last-child):not(.mobile-menu-bloc) {
                border-bottom: $length-1 solid $grey-800;
            }

            &[data-prefix]:before {
                content: "[" attr(data-prefix) "] ";
            }

            &.unread {
                font-weight: bold;
                color: $grey-000;
            }

            .a11y {
                // Ensures the visually hidden assistive text does not
                // forces the visible text to be on the next line.
                position: absolute;
            }

            img, span {
                vertical-align: middle;
            }

            img {
                margin: $length-4 $length-4 $length-4 0;
                width: $length-32;
                height: $length-32;
            }

            .label {
                padding: 0 0 0 $length-48;
            }
            img + .label {
                padding: 0 0 0 $length-8;
            }
        }
    }

    .js.show-mobile-menu {
        width: 100%;

        body {
            position: fixed;
        }

        .page-container {
            transform: translate3d(90%, 0, 0);
            overflow: hidden;
            box-shadow: 0 0 $length-8 rgba($true-black, .25);
        }

        .mobile-menu {
            transform: translate3d(0, 0, 0);
        }
    }

    // Mobile supports sidebar only
    .js.enable-mobile-menu {
        .mobile-menu-hide {
            display: none;
        }

        .page-container {
            .mobile-menu-bloc,
            .mobile-menu-link,
            .search {
                display: none;
            }

            .mobile-menu-btn + .header-logo {
                margin-left: 0;
            }

            // Adapt menu to smartphone with sidebar
            .mobile-menu-btn {
                display: block;

                height: $length-48;
                width: $length-48;

                cursor: pointer;

                &:after {
                    content: " ";

                    position: absolute;
                    top: $length-14;
                    left: $length-12;

                    display: block;

                    // Sprite size
                    height: 22px;
                    width: 22px;

                    background-repeat: no-repeat;
                    @include sprite-position($menu);
                }
            }
        }
    }

    html:not(.enable-mobile-menu) {
        .header-container {
            border-bottom: 1px solid $grey-200;
        }

        .page-container {
            .header-logo {
                margin-left: $length-10;
            }

            .header-logo-link {
                &:after {
                    left: $length-48;
                    right: $length-192;
                }
            }
        }
    }
}
