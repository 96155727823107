// ZMD source code

.hljs-code-div {
  display: flex;

  // Required for line highlights
  position: relative;

  margin: 0 auto;
  padding: $length-4;

  border-radius: $radius-1;

  background-color: $true-white;
  font-family: $font-monospace;
  font-style: normal;
  font-size: $font-size-10;

  line-height: 1.3;

  z-index: 0;

  + .hljs-code-div {
    margin-top: $length-14;
  }

  .hljs-line-numbers {
    counter-reset: line;

    margin: 0;
    padding: $length-6;

    border-right: $length-1 solid $grey-100;

    text-align: right;
    color: $grey-500;

    z-index: 1;

    span:after {
      display: block;
      position: relative;
      top: -$length-1;
    }

    // LEGACY
    // Support for content generated before zmd 9.1, without explicit
    // line numbers.
    span:not([data-count]):after {
      counter-increment: line;
      content: counter(line);
    }

    // Support for content generated after zmd 9.1.
    span[data-count]:after {
      content: attr(data-count);
    }

    // Highlighted lines
    span.hll {
      &:after {
        font-weight: bold;
        color: $accent-900;
      }

      &:before {
        display: block;
        content: '';

        position: absolute;
        left: 0;

        width: 100%;
        height: 1.3em; // This precise height is required for the highlighted lines to not overlap.

        background-color: transparentize($accent-200, .66);
      }
    }
  }

  // Code part
  pre {
    margin: 0;
    width: 100%;
    overflow-x: auto;

    // Required for highlight to work (same for the other one below)
    background-color: transparent;

    z-index: 2;

    .hljs {
      background-color: transparent;
    }
  }

  // This applies only to console codes rendered with zmd 9.1.2+, as previous
  // versions were missing the possibility to style them as full console blocks.
  // Old blocs only have a black background on the content, not the line numbers,
  // and they get a degraded support for line highlight.
  &.hljs-code-console {
    background-color: $black;

    .hljs-line-numbers {
      border-right-color: $grey-600;

      span {
        &:after {
          color: $grey-400;
        }
        &.hll:after {
          color: $grey-200;
        }
      }
    }

    // Required for highlight to work
    pre code.language-console {
      background-color: transparent;
    }
  }
}

// The code part of the console codes (shared for legacy and new versions,
// the background-color being overridden for new versions).
.language-console {
  display: block;
  padding: $length-6;

  color: $color-console-text;
  background-color: $color-console-bg;

  font-family: $font-monospace;

  overflow-x: auto;
}
