
.hat,
.hatlist a.btn {
    // Set height & lh of .hat to be sure to keep
    // the same size with .btn
    line-height: 2.2;
    box-sizing: border-box;
    height: $length-32;

    margin: 0 0 $length-1 0;

    word-wrap: nowrap;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/* hat in a message */
.message .hat {
    /* undo `line-height: 30px;` with `inherit` */
    line-height: inherit;
    margin: $length-6 0;
}

.hat > a.name {
    padding: 0 $length-6;
    border: $length-1 solid transparent;
}

.hat {
    position: relative;

    & > a.name {
        color: $white;
        background-color: $color-hat;

        transition-property: color, background, border;
        transition-timing-function: ease;
        transition-duration: $transition-duration;

        text-decoration: none;
        display: inline-block;
    }

    a.open-modal {
        display: none;
    }

    &:hover a.open-modal {
        position: absolute;
        display: block;

        top: -$length-8;
        right: -$length-8;

        width: $length-18;
        height: $length-18;

        text-align: center;
        text-indent: -$length-1;

        line-height: .85;
        font-size: $font-size-8;

        color: $color-hat;
        text-decoration: none;

        background: $white;
        border: solid $length-1 $color-hat;
        border-radius: $radius-round;

        transition: background-color .2s ease-in-out, color .2s ease-in-out;

        &:hover {
            color: $color-hat;
            text-decoration: none;
            background: $color-hat-hover;
        }
    }

    &.is-staff a.open-modal {
        color: $color-staff-hat;
        border: solid $length-1 $color-staff-hat;

        &:hover {
            background: $color-staff-hat;
        }
    }

    a.name:hover,
    a.name:focus {
        background-color: $color-hat-hover;
        color: $color-hat;
        border: $length-1 solid $color-hat;
    }

    &.staff-hat {
        & > a.name {
            display: inline-flex;
            align-items: center;

            background-color: $color-staff-hat;

            span {
                display: inline-block;
                margin-left: $length-4;

                color: $primary-000;
                font-size: $font-size-11;
            }
        }

        a.name:hover,
        a.name:focus {
            background-color: $color-staff-hat-hover;
            border: $length-1 solid $color-staff-hat;

            &, & span {
                color: $color-staff-hat;
            }
        }
    }
}

.hatlist {
    // reset ul css
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    flex-wrap: wrap;

    li {
        & {
            margin-left: $length-10;

            > a.name {
                padding: 0 $length-10;
            }
        }
    }
}
