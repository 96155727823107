.table-wrapper {
    max-width: 100%;
    overflow: auto;

    & + figcaption {
        margin-top: -$length-14;
    }
}

table {
    margin: $length-14 auto;
    border-top: $length-1 solid $grey-200;
    border-collapse: collapse;

    thead {
        background: $grey-200;
        color: $color-primary;
    }

    th, td {
        text-align: left;
        padding: $length-6 $length-16 $length-6 $length-6;
        border-right: $length-1 solid $grey-200;

        &:first-child {
            border-left: $length-1 solid $grey-200;
        }

        p {
            margin: 0;
        }
    }

    tbody tr {
        background: $grey-100;
        border-bottom: $length-1 solid $grey-200;

        &:nth-child(2n+1) {
            background: $white;
        }
    }

    &.fullwidth {
        width: 100%;
    }
}

// Specific for diff

.diff_delta {
    overflow-x: auto;
    width: 100%;
    margin: $length-16 0;

    table.diff {
        margin: 0; // abort table default margin

        border: $length-2 solid $grey-500;

        font-family: $font-monospace;
        font-size: $font-size-10;

        tr {
            line-height: 1.2;
            border-bottom: none;
        }

        .diff_header {
            background-color: $grey-200;
            padding: $length-4 $length-6;
        }

        td.diff_header {
            text-align: right;
        }

        .diff_next {
            display: none;
        }
    }
}

.diff_add { // added text
    background-color: $green-200;
}

.diff_chg { // changed text
    background-color: $accent-200;
}

.diff_sub { // deleted text
    background-color: $red-100;
}
