.moderation-alert {
    display: flex;
    align-items: center;

    margin-bottom: $length-16;
    padding: $length-8 $length-16;

    background-color: $red-700;

    &, p {
        font-family: $font-sans-serif !important;
    }

    &, a {
        color: $white;
    }

    &.is-solved {
        background-color: $grey-500;
    }

    .moderation-alert-content {
        flex: 2;

        p {
            margin: 0 !important;
            font-size: $font-size-10 !important;
        }

        footer {
            font-size: $font-size-11;
            a.member-item {

            }
        }
    }

    .moderation-alert-actions {
        margin-left: $length-16;
    }
}
