.featured-resource-item {
    position: relative;

    flex: 1;

    margin-right: $length-1;

    max-width: 228px;
    min-width: 170px;

    background-color: $color-primary;

    overflow: hidden;
    z-index: 0;

    &::before {
        content: "";
        display: block;
        padding-top: 100%;
    }

    .featured-resource-illu {
        position: absolute;
        top: 0;
        left: 0;

        width: auto;
        height: 100%;

        z-index: 1;

        transition: $transition-duration ease;
        transition-property: opacity, transform;

        // Force hardware acceleration
        transform: scale(0), translateZ(0);
        perspective: 1000;
        backface-visibility: hidden;
    }

    .featured-resource-meta {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        padding: $length-38 $length-14 $length-12;
        background-image: linear-gradient(to bottom, transparent 0%, rgba($true-black, .2) 30px, rgba($true-black, .4));

        color: $white;
        text-shadow: $length-1 $length-1 0 rgba($true-black, .6);

        z-index: 3;

        h2 {
            display: table-cell;

            margin: 0;
            height: 0;

            vertical-align: middle;
            border: none;

            font-size: $font-size-9;
            font-weight: normal;
            line-height: 1.5;

            color: $white;

            transition: height $transition-duration ease;
         }

         p {
            margin: 0;

            font-size: $font-size-11;
            line-height: 1.5;
         }
    }

    a:focus,
    a:hover {
        .featured-resource-illu {
            opacity: .4;
            filter: blur(5px);

            transform: scale(1.05), translateZ(0);
        }

        .featured-resource-meta {
            h2 {
                height: $length-192;
                font-size: $font-size-7;
            }
        }
    }

    & > a {
        display: block;
    }
}

.featured-resource-edit-form {
    display: flex;
    align-items: flex-start;

    .featured-resource-item {
        margin-right: $length-20;
        flex-basis: 228px;
    }

    form {
        width: auto;
        flex: 1;
    }
}

@include mobile {
    .featured-resource-edit-form {
        flex-direction: column;
        align-items: unset;
    }
}
